$teal: #18c3bb;
$teal-border: #089d96;
$teal-link-border: #2ec8c0;
$teal-background: #7accc8;
$teal-background-light: #c4f0ee;
$teal-dark: #01645f;
$orange: #ff7801;
$pink: #d2096a;
$gray-blue: #6d97b9;
$light-green: #98cf61;
$black: #1e2022; // rgb(30, 32, 34)
$yellow-text: #ffc107;
$white: #ffffff;

$gray: #98a0b0;
$gray-text: #5c667a;
$gray-light: #777;
$gray-lighter: #b8b8b8;
$gray-lightest: #c3ccd2;
$gray-extra-light: #f3f7f9;
$gray-extra-light-home: #eaf0f5;
$gray-border: #d7dee2;
$gray-border-light: #dce0e0;
$gray-backgrounds: #d6dde1;
$gray-dark: #4f596c;
$gray-darker: #292f3b;
$gray-status: #8aaecb;
$gray-shadow: #cdcdcd;

$purple: #933cdd;
$purple-light: #d6baee;
$purple-lighter: #f6eefc;
$purple-dark: #5c667a;
$purple-status: #b791d8;

$green: #2fa205;
$green-light: #b3e8a0;
$green-extra-light: #ddf2d5;
$green-dark: #1d6b01;

$blue-light: #edfbfa;
$blue-text-secondary: #b9cbeb;
$blue-dark: #252e54;
$blue-darker: #050f39;
$blue-darkest: #001234;

$yellow-light: #fcf6ed;

$fuxia-background: #f9ebf2;
$fuxia-border: #f9cae0;
$fuxia: #e5167a;

$semi-transparent-dark-bg: rgba(#333, 0.7);
$orange-light: #ff9c44;
$orange-lighter: #ffd7b4;
$orange-dark: #9d4e09;
$silver-light: #c5c3c3;
$yellow: #ffd925;
$green-lighter: #8dc63f;
$silver-lighter: #bbbbc1;

$sorbus: #e564a2;
$red: #ff2e37;
$red-darker: #de2038;

@import "../../../../../../styles/mixins";

$grid-breakpoint-xs: 0;
$grid-breakpoint-sm: 600px;
$grid-breakpoint-md: 1024px;
$grid-breakpoint-lg: 1624px;
$gutter-width-mobile: 8px;
$gutter-width: 24px;

$grid-margin: 20px;
$neg-grid-margin: -20px;

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: $neg-grid-margin;
}

.flex {
  display: flex;
}

.flexColumn {
  flex-direction: column;
}

// Manually create specific gap classes
.gap1 {
  @include gap(1);
}

.gap2 {
  @include gap(2);
}

.gap3 {
  @include gap(3);
}

.gap4 {
  @include gap(4);
}

.gap5 {
  @include gap(5);
}

.column {
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: $grid-margin;
}

@media screen and (min-width: $grid-breakpoint-xs) {
  .column {
    flex-basis: 100%;
  }
}

@media screen and (min-width: $grid-breakpoint-sm) {
  .column {
    flex-basis: 50%;
  }
}

@media screen and (min-width: $grid-breakpoint-md) {
  .column {
    flex-basis: 33.3%;
  }
}

@media screen and (min-width: $grid-breakpoint-lg) {
  .column {
    flex-basis: 25%;
  }
}

$font-size-base: 15px;
$font-weight-base: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 600;
$font-family-sans-serif: "Poppins";
$font-family-base: $font-family-sans-serif, sans-serif;
$text-color: #222;
$text-additional: #5c667a;
$text-disabled: #777;

// Transition defaults
$transition-duration: 0.05s;
$transition-timing-function: ease-in-out;

@mixin outline($color: rgba(#29d, 0.3)) {
  outline: none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 2px $color;
}

@mixin triangle($direction, $width, $height, $color) {
  @if ($direction == "up") {
    transform: rotate(135deg);
  } @else if ($direction == "down") {
    transform: rotate(-45deg);
  } @else if ($direction == "left") {
    transform: rotate(45deg);
  } @else if ($direction == "right") {
    transform: rotate(-135deg);
  }

  transform-origin: 0 0;
  border-width: $height calc($width / 2) 0;
  border-style: solid;
  border-color: transparent transparent $color $color;
  box-shadow: 0 2px 15px 0 rgba(30, 32, 34, 0.15);
  background-color: $color;
  width: 0;
  height: 0;
  content: "";
}

@mixin arrow($size, $color) {
  display: block;
  position: absolute;
  left: 50%;
  bottom: -($size);
  margin-left: -($size);
  border-width: $size;
  border-bottom-width: 0;
  border-style: solid;
  border-color: transparent;
  border-top-color: $color;
  width: 0;
  height: 0;
}

@mixin arrow-after($size, $color) {
  display: block;
  position: absolute;
  bottom: 2px;
  margin-left: -(calc($size - 1px));
  border-width: calc($size - 1px);
  border-bottom-width: 0;
  border-style: solid;
  border-color: transparent;
  border-top-color: $color;
  width: 0;
  height: 0;
  content: " ";
}

@mixin arrow-with-shadow($arrow-size, $border-color, $arrow-color) {
  @include arrow($arrow-size, $border-color);

  &::after {
    @include arrow-after($arrow-size, $arrow-color);
  }
}

@mixin multi-line-ellipsis($line-height, $line-count, $bg-color) {
  overflow: hidden;
  position: relative;
  line-height: $line-height;
  max-height: calc($line-height * $line-count);
  margin-right: -1em;
  padding-right: 0.5em;
  &:before {
    content: "...";
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bg-color;
  }
}

@mixin hideScrollBars {
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox

  ::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

@function gap-size($number) {
  @return calc($number / 4) * 1rem;
}

@mixin gap($number) {
  gap: gap-size($number);
}

$screen-xs-slider: 320px;
$screen-xs: 375px;
$screen-sm: 768px;
$screen-md: 1024px;
$screen-lg: 1280px;
$screen-xl: 1440px;

@mixin large-and-extra-large {
  @media (min-width: calc($screen-md + 1px)) {
    @content;
  }
}

@mixin extra-large {
  @media (min-width: calc($screen-lg + 1px)) {
    @content;
  }
}

@mixin large {
  @media (min-width: calc($screen-md + 1px)) and (max-width: $screen-lg) {
    @content;
  }
}

@mixin medium {
  @media (min-width: calc($screen-sm + 1px)) and (max-width: $screen-md) {
    @content;
  }
}

@mixin small {
  @media (min-width: calc($screen-xs + 1px)) and (max-width: $screen-sm) {
    @content;
  }
}

@mixin xs {
  @media (max-width: $screen-xs) {
    @content;
  }
}

@mixin xs-slider {
  @media (max-width: $screen-xs-slider) {
    @content;
  }
}

@mixin medium-and-large {
  @media (min-width: calc($screen-sm + 1px)) {
    @content;
  }
}

@mixin small-and-medium {
  @media (max-width: $screen-md) {
    @content;
  }
}

@mixin xs-and-small {
  @media (max-width: $screen-sm) {
    @content;
  }
}

@mixin slv-large {
  @media (min-width: calc($screen-xl + 1px)) {
    @content;
  }
}

@mixin slv-medium {
  @media (min-width: calc($screen-lg + 1px)) and (max-width: $screen-xl) {
    @content;
  }
}

@mixin slv-small {
  @media (max-width: $screen-lg) {
    @content;
  }
}

@mixin slv-small-and-medium {
  @media (max-width: $screen-xl) {
    @content;
  }
}

@mixin smallDesktops {
  @media (max-width: calc($screen-xl - 1px)) {
    @content;
  }
}

@mixin ipads {
  @media (max-width: $screen-md) {
    @content;
  }
}

@mixin phones {
  @media (max-width: calc($screen-sm - 1px)) {
    @content;
  }
}

// Global
$z-index-file-uploader-overlay: calc(2147483647 + 1); // chat icon + 1
$z-index-dropdown-body: 101;
$z-index-navbar: 1000;
$z-index-filters: 999;
$z-index-tooltip: 998;
$z-index-date-picker: 997;
$z-index-video-player-overlay: 1001;
$z-index-popover: 1;
$z-index-popver-triangle: 1;
$z-index-slick-arrow: 1;

// Dashboard
$z-index-dashboard-product-editor-action-bar: 100;
$z-index-dashboard-product-index-action-bar: 100;
$z-index-dashboard-product-index-notification-bar: 200;

// Marketplace
$z-index-mobile-filters: calc($z-index-navbar + 1);
$z-index-mobile-filters-action-bar: calc($z-index-mobile-filters + 1);

// Locations
$z-index-locations-map: calc($z-index-navbar + 1);
$z-index-locations-toggle-map: calc($z-index-locations-map + 1);
$z-index-locations-mobile-map: calc($z-index-navbar - 1);

// Modal navigation header
$z-index-modal-navigation-header: 1;
$z-index-invitation-modal: 1010;
$z-index-invitation-modal-overlay: 1000;

@import "app/styles/fonts";
@import "app/styles/colors";
@import "app/styles/z-indexes";
@import "app/styles/screens";

.proposalDropdownTrigger {
  background-color: $orange-dark;
  border-radius: 20px;
  height: 40px;
  color: $white;
  border: none;

  &.inProgress {
    background-color: $gray-status;
    border-color: $gray-status;
  }

  &.finalized {
    background-color: $light-green;
    border-color: $light-green;
    cursor: auto;
  }

  &.cancelled {
    background-color: $sorbus;
    border-color: $sorbus;
    cursor: auto;

    @include xs-and-small {
      margin: 10px 0 10px 0;
    }

    @include medium {
      max-width: 75%;
      margin-left: 10px;
    }
  }
  &.preparing {
    background-color: $gray-lightest;
    border-color: $gray-lightest;
  }
  &.preparingCursor {
    cursor: auto;
  }
  &.nobid {
    background-color: $purple-status;
    border-color: $purple-status;
    cursor: auto;
  }

  &.expired {
    background-color: $orange-light;
    border-color: $orange-light;
    cursor: auto;
  }

  &.changing {
    background-color: $teal;
    border-color: $teal;
  }

  &:focus {
    border: none;
  }

  .pillTitle {
    display: flex;
    align-items: center;
    font-size: 14px;

    svg {
      margin: 2px 10px 0 5px;
    }
  }
}

.dropdownBody {
  max-height: 250px;
  overflow-y: auto;
  display: block;
  padding: 20px;
  width: 100%;

  button {
    margin-top: 10px;
  }
}

.dropdownBodyRow {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid $gray-border-light;
  text-align: left;

  svg {
    margin-right: 14px;
  }

  .label {
    width: 155px;
    font-size: 14px;
    line-height: 16px;
    font-weight: $font-weight-base;
    margin: 0;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    color: $gray-text;
  }

  &:last-child {
    padding-bottom: 0;
    border: none;
  }

  &:first-child {
    padding-top: 0;
  }
}

.modalMessageOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: $semi-transparent-dark-bg;
  z-index: $z-index-navbar;
}

.proposalModalMessageContainer {
  width: 300px;
  height: 130px;
  background-color: $white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.proposalModalMessageText {
  font-weight: $font-weight-bold;
  color: $black;
  font-size: 22px;
}

.modalMessageButtons {
  display: flex;
  justify-content: space-between;
}

.statusMessageButtons {
  display: flex;
  margin-top: 30px;

  & > * {
    margin-right: 10px;
  }
}

.closeOverlayButton {
  position: absolute;
  top: 20px;
  right: 20px;
}

.statusMessageModalContainer {
  width: 500px;
  height: 450px;
  background-color: $white;
  border-radius: 10px;
  position: relative;
  padding: 60px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  .statusMessageModalContent {
    h2 {
      font-size: 32px;
      color: $black;
      font-weight: $font-weight-semibold;
      margin: 0 0 0.5rem 0;
    }

    .label {
      color: $gray-text;
      font-size: $font-size-base -1px;
      font-weight: $font-weight-bold;
      line-height: 20px;
      display: inline-block;
      margin-bottom: 0.5rem;
    }

    .message {
      font-size: 14px;
      line-height: 20px;
    }

    .description {
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 20px;
      color: $gray-text;
      margin-top: 0;
    }
  }
}
